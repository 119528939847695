<template>
  <div class="pagination-block" v-if="pagination && pagination.total">
    <div class="pagination-showing">Showing <strong>{{pagination.from}}-{{pagination.to}}</strong> of {{pagination.total}}</div>
    <template v-if="pagination.last_page > 1">
      <el-pagination
        background
        :hide-on-single-page="true"
        :page-size="1 * pagination.limit"
        :current-page.sync="pagination.page"
        layout="prev, pager, next"
        :pager-count="7"
        @current-change="changePage"
        :total="pagination.total">
      </el-pagination>
      
      <!-- <div class="pagination-jumper">
        <div class="pagination-jumper-label">Go to page</div>
        <div class="pagination-jumper-input">
          <input type="number" v-model="inputPage" @change="changePage(Number(inputPage))">
        </div>
      </div> -->
    </template>
  </div>
</template>

<script>
export default {
  props: ['pagination'],
  data() {    
    return {
      inputPage: 1
    }
  },
  computed:{
  },
  created(){
    this.inputPage = this.pagination.page;
  },
  methods: {
    changePage(page){
      this.inputPage = page;
      this.$emit('changePage', page)
    }
  }
}
</script>