import {method} from "@/api/api";

export const actions = {
    getList({commit}) {
        return method("hrm.rights").then(res => {
            if (res['success'] && res['data']) {
                commit('SET_LIST', res['data']);
            }
        });
    },
    update({commit}, params) {
        return method('hrm.rights.update', params)
    },
}