import { orderBy } from '@/utils/index'
import i18n from "@/plugins/i18n";
export const mutations = {
  SET_COUNTRIES: (state, data) => {
    state.countries = orderBy(data, 'name_'+i18n.locale);
  },
  SET_FORM_OPTIONS: (state, data) => {
    state.formOptions = data;
  },
  SET_INN_LIST: (state, data) => {
    state.innList = data
  }
};