export const mutations = {
  SET_LIST: (state, data) => {
    if (data) {
      state.list = data.data;
      state.pagination = {
        total: data ? data.total : 0,
        from: data ? data.from : 0,
        to: data ? data.to : 0,
        last_page: data ? data.last_page : 0,
        page: data ? data.current_page : 0,
        limit: data ? data.per_page : 0,
        startIndex: (data["current_page"] - 1) * data["per_page"] + 1,
      };
    } else {
      state.list = [];
      state.pagination = null;
    }
  },

  SET_REGION_DATA: (state, data) => (state.regionTable = data),
};
