import { method } from '@/api/api';

export const actions = {
  bySteps({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("crm.kpi.steps", data)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_STEPS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  byPositions({ commit }, data) {
    commit('SET_POSITIONS', []);
    return new Promise((resolve, reject) => {
      method("crm.kpi.positions", data)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_POSITIONS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  byPositionsNew({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("crm.kpi.positions-new", data)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_POSITIONS_NEW', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  byPositionsExports({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.kpi.positions-export", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_POSITIONS_EXPORT', {data: res['data'], params});
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  setPositionsSort({ commit }, params){
    commit('SET_POSITIONS_SORT', params);
  },
  byStructures({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.kpi.structures-new", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_STRUCTURES', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
