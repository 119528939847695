import { method } from '@/api/api';

export const actions = {
  getList({ commit },params) {
    return method("crm.tasks-boards.list", params);
  },
  create({ commit }, data) {
    return method("crm.tasks-boards.create", data);
  },
  update({ commit }, data) {
    return method("crm.tasks-boards.update", data);
  },
  destroy({ commit }, data) {
    return method("crm.tasks-boards.delete", data);
  },
  show({ commit }, data) {
    return method("crm.tasks-boards.show", data);
  },
};
