<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getToken, checkTokenTime } from "@/utils/auth";
export default {
  data(){
    return {
    }
  },
  created() {
    this.checkAuth();
    this.cacheClear();
    this.getFormOptions();
    this.getTicketStatuses();
  },
  computed: {},
  methods: {
    ...mapActions({
      getCurrentUser: 'auth/currentUser',
      getFormOptions: 'resource/formOptions',
      getSourcesAction: 'cardSources/getList',
      getTicketStatuses: 'tickets/getTicketStatuses',
      getCategories: 'cardSources/getCategories'
    }),
    cacheClear(){
      if(caches){
        caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
      }
    },
    checkAuth(){
      let token = getToken();
      if(token && checkTokenTime()){
        this.getCurrentUser();
        this.getSourcesAction();
        this.getCategories();
      }
    }
  }
};
</script>