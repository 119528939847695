import {method} from "@/api/api";

export const actions = {
    getList({commit}, params) {
        method('help.ticket-templates.list', params).then(res => {
            commit('SET_TEMPLATES', res.data)
        })
    },
    create({commit}, params) {
        return method('help.ticket-templates.create', params)
    },
    update({commit}, params) {
        return method('help.ticket-templates.update', params)
    },
    destroy({commit}, id) {
        return method('help.ticket-templates.delete', id)
    }
}