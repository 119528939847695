import Vue from "vue";
import VueRouter from "vue-router";
import middlewares from "./middlewares";
import store from "@/store/";

import hrm from "./modules/hrm";
import crm from "./modules/crm";
import kpi from "./modules/kpi";
import helpDesk from "./modules/help-desk";
import localGlobal from "@/router/modules/local-global";
import regionsRating from "@/router/modules/regions-rating";

import { checkTokenTime, clearForLogout } from "@/utils/auth";

Vue.use(VueRouter);

var modules = [];
modules = modules.concat(hrm);
modules = modules.concat(crm);
modules = modules.concat(kpi);
modules = modules.concat(helpDesk);
modules = modules.concat(localGlobal);
modules = modules.concat(regionsRating);

const routes = [
  {
    path: "/",
    name: "Main",
    meta: { middleware: "Auth" },
    component: () => import("@/layouts/Main"),
    children: modules,
  },
  {
    path: "/generate-locales",
    name: "GenerateLocales",
    component: () => import("@/GenerateLocales"),
  },
  {
    path: "/",
    name: "Empty",
    component: () => import("@/layouts/Empty"),
    children: [
      {
        path: "/login",
        name: "Login",
        component: () => import("@/views/auth/Login"),
      },{
        path: "/login-foreign",
        name: "LoginForeign",
        component: () => import("@/views/auth/LoginForeign"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!to.meta["savePosition"]) {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.middleware) {
    let $res = checkMiddleware(to.meta.middleware);
    if (!$res || !checkTokenTime()) {
      clearForLogout();
      return next({ name: "Login", query: { redirect_url: to.path } });
    }
  }
  return next();
});

function checkMiddleware(middleware) {
  var middlewareSplit = middleware.split("|");
  var result = false;
  middlewareSplit.forEach((middlewareItem) => {
    if (middlewares[middlewareItem]) {
      let $res = middlewares[middlewareItem](store);
      if ($res) result = true;
    }
  });
  return result;
}

export default router;
