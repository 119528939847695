const routes = [
  {
    path: "/regions-rating",
    name: "AllRatings",
    meta: { middleware: "Auth" },
    component: () => import("@/views/regionsRating/Index.vue"),
  },
  {
    path: "/regions-rating/talents",
    name: "TalentsRatings",
    meta: { middleware: "Auth" },
    component: () => import("@/views/regionsRating/talents/table.vue"),
  },
  {
    path: "/regions-rating/talents/entered-data",
    name: "TalentsEnteredData",
    meta: { middleware: "Auth" },
    component: () => import("@/views/regionsRating/talents/entered-data.vue"),
  },
  {
    path: "/regions-rating/talents/entered-data/add",
    name: "TalentsEnteredDataAdd",
    meta: { middleware: "Auth" },
    component: () => import("@/views/regionsRating/talents/showActions.vue"),
  },
  {
    path: "/regions-rating/talents/entered-data/edit/:id",
    name: "TalentsEnteredDataEdit",
    meta: { middleware: "Auth" },
    component: () => import("@/views/regionsRating/talents/showActions.vue"),
  },
  {
    path: "/regions-rating/talents/entered-data/show/:id",
    name: "TalentsEnteredDataShow",
    meta: { middleware: "Auth" },
    component: () => import("@/views/regionsRating/talents/show.vue"),
  },
  {
    path: "/regions-rating/talents/confirmed-data/show/:id",
    name: "TalentsConfirmedDataShow",
    meta: { middleware: "Auth" },
    component: () => import("@/views/regionsRating/talents/show.vue"),
  },
  {
    path: "/regions-rating/talents/confirmed-data",
    name: "TalentsConfirmedData",
    meta: { middleware: "Auth" },
    component: () => import("@/views/regionsRating/talents/confirmed-data.vue"),
  },

  {
    path: "/regions-rating/infrastructure",
    name: "Infrastructure",
    meta: { middleware: "Auth" },
    component: () => import("@/views/regionsRating/infrastructure.vue"),
  },
  {
    path: "/regions-rating/infrastructure/entered-data",
    name: "InfrastructureData",
    meta: { middleware: "Auth" },
    component: () => import("@/views/regionsRating/infrastructureData.vue"),
  },

  {
    path: "/regions-rating/local-team",
    name: "LocalTeam",
    meta: { middleware: "Auth" },
    component: () => import("@/views/regionsRating/localTeam.vue"),
  },
  {
    path: "/regions-rating/local-team/entered-data",
    name: "EnteredData",
    meta: { middleware: "Auth" },
    component: () => import("@/views/regionsRating/enteredData.vue"),
  },
];

export default routes;
