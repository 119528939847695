import { method } from '@/api/api';

export const actions = {
  getCategories({ commit },params) {
    return new Promise((resolve, reject) => {
      method("crm.cards-sosource-categories.list", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_CATEGORIES', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createCategory({ commit }, data) {
    return method("crm.cards-sosource-categories.create", data);
  },
  updateCategory({ commit }, data) {
    return method("crm.cards-sosource-categories.update", data);
  },
  destroyCategory({ commit }, data) {
    return method("crm.cards-sosource-categories.delete", data);
  },

  getList({ commit },params) {
    return new Promise((resolve, reject) => {
      method("crm.card-source.list", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  create({ commit }, data) {
    return method("crm.card-source.create", data);
  },
  update({ commit }, data) {
    return method("crm.card-source.update", data);
  },
  destroy({ commit }, data) {
    return method("crm.card-source.delete", data);
  },
};
