import { method } from '@/api/api';

export const actions = {
  getList({ commit },params) {
    return method("crm.tasks-workspaces.list", params);
  },
  create({ commit }, data) {
    return method("crm.tasks-workspaces.create", data);
  },
  update({ commit }, data) {
    return method("crm.tasks-workspaces.update", data);
  },
  destroy({ commit }, data) {
    return method("crm.tasks-workspaces.delete", data);
  },
  show({ commit }, data) {
    return method("crm.tasks-workspaces.show", data);
  },
};
