const routes = [
    {
        path: '/help-desk',
        name: 'HelpDesk',
        meta: {middleware: 'Auth'},
        redirect: {name: 'Tickets'},
        component: () =>
            import("@/views/help-desk/tickets/index.vue"),
    },
    {
        path: '/help-desk/tickets',
        name: 'Tickets',
        meta: {middleware: 'Auth'},
        component: () =>
            import("@/views/help-desk/tickets/index.vue"),
    },
    {
        path: '/help-desk/tickets/show/:id',
        name: 'TicketsShow',
        meta: {middleware: 'Auth'},
        component: () =>
            import("@/views/help-desk/tickets/show.vue"),
    },
    {
        path: '/help-desk/tickets/create',
        name: 'TicketsCreate',
        meta: {middleware: 'Auth'},
        component: () =>
            import("@/views/help-desk/tickets/create.vue"),
    },
    {
        path: '/help-desk/tickets/edit',
        name: 'TicketsEdit',
        meta: {middleware: 'Auth'},
        redirect: {name: 'TicketsCreate'},
        component: () =>
            import("@/views/help-desk/tickets/create.vue"),
    },
    {
        path: '/help-desk/ticket-categories',
        name: 'TicketCategories',
        meta: {middleware: 'Auth'},
        component: () =>
            import("@/views/help-desk/tickets/categories.vue"),
    },
    {
        path: '/help-desk/categories',
        name: 'FAQCategories',
        meta: {middleware: 'Auth'},
        component: () =>
            import("@/views/help-desk/FAQ/faq-categories.vue"),
    },
    {
        path: '/help-desk/articles',
        name: 'FAQArticles',
        meta: {middleware: 'Auth'},
        component: () =>
            import("@/views/help-desk/FAQ/index.vue"),
    },
    {
        path: '/help-desk/articles/show',
        name: 'FAQArticleSHow',
        meta: {middleware: 'Auth'},
        component: () =>
            import("@/views/help-desk/FAQ/show.vue"),
    },
    {
        path: '/help-desk/templates',
        name: 'ReplyTemplates',
        meta: {middleware: 'Auth'},
        component: () =>
            import("@/views/help-desk/templates.vue"),
    },
]

export default routes;
