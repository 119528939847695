import { method } from "@/api/api";

export const actions = {
  getList({ commit }, params) {
    return method("crm.cards.list", params);
  },
  create({ commit }, data) {
    return method("crm.cards.create", data);
  },
  update({ commit }, data) {
    return method("crm.cards.update", data);
  },
  show({ commit }, data) {
    return method("crm.cards.show", data);
  },
  destroy({ commit }, data) {
    return method("crm.cards.delete", data);
  },
  shortlist({ commit }, data) {
    return method("crm.cards.shortlist", data);
  },
  histories({ commit }, data) {
    return method("crm.cards.histories", data);
  },
  leadHistories({ commit }, data) {
    return method("crm.lead-cards.histories", data);
  },
  storeComments({ commit }, data) {
    return method("crm.cards.create-comments", data);
  },
  storeLeadComments({ commit }, data) {
    return method("crm.lead-cards.create-comments", data);
  },
  getComments({ commit }, data) {
    return method("crm.cards.get-comments", data);
  },
  archived({ commit }, data) {
    return method("crm.cards.archived", data);
  },
  getResidentByTin({ commit }, data) {
    return method("crm.cards.residentByTin", data);
  },
  filedelete({ commit }, data) {
    return method("crm.cards.filedelete", data);
  },
  exportAmount({ commit }, data) {
    return method("crm.cards.export", data);
  },
  structuresList({ commit }, data) {
    return method("crm.cards.structures-list", data);
  },
  getCardsExcel({ commit }, params) {
    return method("crm.cards.export-excel", params);
  },
  companyExists({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.cards.check-exists", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_COMPANY_EXIST", res.data);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
