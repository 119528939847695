import { method } from '@/api/api';

export const actions = {
  getList({ commit },params) {
    return method("crm.task-steps.list", params);
  },
  getListWithCards({ commit },params) {
    return new Promise((resolve, reject) => {
      method("crm.task-steps.list-cards", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getListWithCardsArchive({ commit },params) {
    return new Promise((resolve, reject) => {
      method("crm.task-steps.list-cards-archive", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST_ARCHIVE', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  create({ commit }, data) {
    return method("crm.task-steps.create", data);
  },
  update({ commit }, data) {
    return method("crm.task-steps.update", data);
  },
  show({ commit }, data) {
    return method("crm.task-steps.show", data);
  },
  destroy({ commit }, data) {
    return method("crm.task-steps.delete", data);
  },
  shortlist({ commit }, data) {
    return method("crm.task-steps.shortlist", data);
  },
  updateOrders({ commit }, data) {
    return method("crm.task-steps.update-orders", data);
  },
};
