import { method } from "@/api/api";

export const actions = {
  getList({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("hrm.employees.list", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_LIST", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getEmployeeAwards({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("hrm.employee-awards.list", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_EMPLOYEE_AWARDS", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getBirthdays({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("hrm.employees.birthday", params)
        .then((res) => {
          commit("SET_BIRTHDAY", res["data"]);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createForeign({ commit }, data) {
    return method("hrm.employees.create-foreign", data);
  },
  create({ commit }, data) {
    return method("hrm.employees.create", data);
  },
  update({ commit }, data) {
    return method("hrm.employees.update", data);
  },
  updateCertificate({ commit }, data) {
    return method("hrm.employees.edit-certificate", data);
  },
  deleteCertificate({ commit }, data) {
    return method("hrm.employees.delete-certificate", data);
  },
  show({ commit }, data) {
    return method("hrm.employees.show", data);
  },
  getCertificates({ commit }, data) {
    return method("hrm.employees.certificates", data);
  },
  destroy({ commit }, data) {
    return method("hrm.employees.delete", data);
  },
  shortlist({ commit }, data) {
    return method("hrm.employees.shortlist", data);
  },
  personByPin({ commit }, data) {
    return method("hrm.employees.personByPin", data);
  },
  personByPinForDev({ commit }, data) {
    return method("hrm.employees.personByPinExample", data);
  },
  history({ commit }, data) {
    return method(`hrm.employee-histories`, data);
  },
  awardsList({ commit }, data) {
    return method(`hrm.awards.list`, data);
  },
  awardsCreate({ commit }, data) {
    return method(`hrm.employee-awards.create`, data);
  },
  awardsUpdate({ commit }, data) {
    return method(`hrm.employee-awards.update`, data);
  },
  awardsDelete({ commit }, data) {
    return method(`hrm.employee-awards.delete`, data);
  },
  egovDiplom({ commit }, data) {
    return method(`hrm.egov.diplom`, data);
  },
  egovWork({ commit }, data) {
    return method(`hrm.egov.work`, data);
  },
  getInstitutionsList({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("hrm.resource.otms", params)
        .then((res) => {
          commit("SET_INSTITUTIONS", res["data"]);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getEmployeesExcel({ commit }, params) {
    return method(`hrm.employees.export`, params);
  },
  getEmployeesBirthdayExcel({ commit }, params) {
    return method(`hrm.employees.birthday-excel`, params);
  },
};
