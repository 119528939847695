import {method} from "@/api/api";

export const actions = {
    getCategories({commit}, params) {
        method('help.ticket-categories.list', params).then(res => {
            commit('SET_CATEGORIES', res.data)
        })
    },
    create({commit}, params) {
        return method('help.ticket-categories.create', params)
    },
    update({commit}, params) {
        return method('help.ticket-categories.update', params)
    },
    destroy({commit}, id) {
        return method('help.ticket-categories.delete', id)
    }
}