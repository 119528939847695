import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./plugins/ElementUi";
import "./plugins/Directives";
import "./plugins/Filters";
import "./plugins/VueCarousel";
import "./plugins/Components";
import "./plugins/CKEditor";
import "./plugins/Moment";
import "./plugins/VueDragscroll";
import "./plugins/ElSelectTree";
import i18n from "./plugins/i18n";
import "./plugins/VueTelInput";
import "./plugins/lodash";
import "./mixins";
import "./assets/styles/styles.scss";
import "./assets/styles/media.scss";

Vue.config.productionTip = false;

import { getItem, setItem } from "@/utils/storage";
window.onerror = function (msg, url, lineNo, columnNo, error) {
  let err_msg = "Uncaught SyntaxError: Unexpected token '<'";
  let err_url = "/js/chunk-";
  let currentDate = Math.floor(Date.now() / 1000);
  let chunk_err = getItem("chunk_err_date");

  let message = [
    "Message: " + msg,
    "URL: " + url,
    "Line: " + lineNo,
    "Column: " + columnNo,
    "Error object: " + JSON.stringify(error),
  ].join(" - ");
  console.log("window.onerror", message);

  if (msg.includes(err_msg)) {
    let secund = 5; // 5 - minutes
    if (chunk_err && chunk_err + secund < currentDate) {
      setItem("chunk_err_date", currentDate);
      location.reload();
    } else if (!chunk_err) {
      setItem("chunk_err_date", currentDate);
      location.reload();
    }
  }
};

new Vue({
  router,
  store,
  i18n,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
