import { method } from '@/api/api';

export const actions = {
    getList({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("hrm.positions.list", params)
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_LIST', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getListArchive({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("hrm.positions.list-archived", params)
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_LIST_ARCHIVED', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    create({ commit }, data) {
        return method("hrm.positions.create", data);
    },
    update({ commit }, data) {
        return method("hrm.positions.update", data);
    },
    show({ commit }, data) {
        return method("hrm.positions.show", data);
    },
    destroy({ commit }, data) {
        return method("hrm.positions.delete", data);
    },
    shortlist({ commit }, data) {
        return method("hrm.positions.shortlist", data);
    },
};