import { method } from "@/api/api";

export const actions = {
  getList({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("hrm.local-team-reestr.list", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_LIST", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  create({ commit }, data) {
    return method("hrm.local-team-reestr.create", data);
  },
  edit({ commit }, data) {
    return method("hrm.local-team-reestr.edit", data);
  },
  delete({ commit }, data) {
    return method("hrm.local-team-reestr.delete", data);
  },
  statisticsByRegions({ commit }, data) {
    return method("hrm.local-team-reestr.statistics-by-region", data);
  },
  getTableInfrastructure({ commit }, data) {
    return method("crm.infrastructures.statistics-for-general-ranking", data);
  },
  getTableLocalTeam({ commit }, data) {
    return method("hrm.local-team-reestr.statistics-for-general-ranking", data);
  },
};
