import { mapGetters } from "vuex";

export default {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            card_sourses: 'cardSources/LIST',
            card_source_categories: 'cardSources/CATEGORIES'
        })
    },
    created() {
    },
    unmounted() {},
    methods: {
        getCardSourseName(id) {
            var model = this.card_sourses.find(item => item.id == id);
            return model ? model.name : this.$t("Добавлен вручную");
        }
    }
};