import { method } from '@/api/api';

export const actions = {
    getList({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("hrm.structures.list", params)
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_LIST', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getListArchive({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("hrm.structures.list-archived", params)
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_LIST_ARCHIVE', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    create({ commit }, data) {
        return method("hrm.structures.create", data);
    },
    update({ commit }, data) {
        return method("hrm.structures.update", data);
    },
    orderUpdate({ commit }, data){
        return method('hrm.structures.order-update', data)
    },
    show({ commit }, data) {
        return method("hrm.structures.show", data);
    },
    destroy({ commit }, data) {
        return method("hrm.structures.delete", data);
    },
    shortlist({ commit }, data) {
        return method("hrm.structures.shortlist", data);
    },
};