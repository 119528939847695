import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import auth from "./auth";
import loader from "./loader";
import users from "./users";
import app from "./app";
import filials from "./filials";
import structures from "./structures";
import positions from "./positions";
import employees from "./employees";
import steps from "./steps";
import cards from "./cards";
import resource from "./resource";
import taskSteps from "./taskSteps";
import tasks from "./tasks";
import kpi from "./kpi";
import taskWorkspaces from "./taskWorkspaces";
import taskBoards from "./taskBoards";
import cardSources from "./cardSources";
import tickets from "./tickets";
import categories from "./categories";
import articles from "./articles";
import faqCategories from "./faqCategories";
import ticketTemplates from "./ticketTemplates";
import rights from "./rights";
import notifications from "./notifications";
import local2Global from "./local2Global";
import enteredData from "./enteredData";
import infrastructure from "./infrastructure";
import talents from "./talents";

const store = new Vuex.Store({
  modules: {
    auth,
    loader,
    users,
    app,
    filials,
    structures,
    positions,
    employees,
    steps,
    cards,
    resource,
    taskSteps,
    tasks,
    kpi,
    taskWorkspaces,
    taskBoards,
    cardSources,
    tickets,
    categories,
    articles,
    faqCategories,
    ticketTemplates,
    rights,
    notifications,
    local2Global,
    enteredData,
    infrastructure,
    talents,
  },
});

export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetState() {
  let storeData = JSON.parse(JSON.stringify(store.state));
  initialStateCopy["classifiers"] = storeData["classifiers"];
  initialStateCopy["auth"]["is_auth"] = false;
  initialStateCopy["auth"]["user"] = null;
  initialStateCopy["auth"]["role_name"] = "";
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}
