import { setItem } from '@/utils/storage';

export const mutations = {
  SET_USER: (state, data) => {
    state.user = data;
    if (state.user) {
      state.role_name = state.user.role;
      setItem('user', state.user);
    }
  },
  SET_IS_AUTH: (state, value) => {
    state.is_auth = value;
  },
};