export const state = {
  prevRoute: {},
  regions: [
    {
      ns10: 35,
      soato: "1735",
      title: 'Республика Каракалпакстан',
      title_uz: 'Qoraqalpog`iston Respublikasi',
      title_cl: 'Қорақалпоғистон Республикаси',
      title_en: 'Karakalpakstan Republic',
      admincenter_ru: 'г. Hукус',
      admincenter_uz: 'Nukus sh.'
    },
    {
      ns10: 3,
      soato: "1703",
      title: 'Андижанская область',
      title_uz: 'Andijon viloyati',
      title_cl: 'Андижон вилояти',
      title_en: 'Andijan region',
      admincenter_ru: 'г. Андижан',
      admincenter_uz: 'Andijon sh.'
    },
    {
      ns10: 6,
      soato: "1706",
      title: 'Бухарская область',
      title_uz: 'Buxoro viloyati',
      title_cl: 'Бухоро вилояти',
      title_en: 'Bukhara region',
      admincenter_ru: 'г. Бухара',
      admincenter_uz: 'Buxoro sh.'
    },
    {
      ns10: 8,
      soato: "1708",
      title: 'Джизакская область',
      title_uz: 'Jizzax viloyati',
      title_cl: 'Жиззах вилояти',
      title_en: 'Jizzakh region',
      admincenter_ru: 'г. Джизак',
      admincenter_uz: 'Jizzax sh.'
    },
    {
      ns10: 10,
      soato: "1710",
      title: 'Кашкадарьинская область',
      title_uz: 'Qashqadaryo viloyati',
      title_cl: 'Қашқадарё вилояти',
      title_en: 'Kashkadarya region',
      admincenter_ru: 'г. Карши',
      admincenter_uz: 'Qarshi sh.'
    },
    {
      ns10: 12,
      soato: "1712",
      title: 'Навоийская область',
      title_uz: 'Navoiy viloyati',
      title_cl: 'Навоий вилояти',
      title_en: 'Navoiy region',
      admincenter_ru: 'г. Навои',
      admincenter_uz: 'Navoiy sh.'
    },
    {
      ns10: 14,
      soato: "1714",
      title: 'Наманганская область',
      title_uz: 'Namangan viloyati',
      title_cl: 'Наманган вилояти',
      title_en: 'Namangan region',
      admincenter_ru: 'г. Наманган',
      admincenter_uz: 'Namangan sh.'
    },
    {
      ns10: 18,
      soato: "1718",
      title: 'Самаркандская область',
      title_uz: 'Samarqand viloyati',
      title_cl: 'Самарқанд вилояти',
      title_en: 'Samarkand region',
      admincenter_ru: 'г. Самарканд',
      admincenter_uz: 'Samarqand sh.'
    },
    {
      ns10: 24,
      soato: "1724",
      title: 'Сырдарьинская область',
      title_uz: 'Sirdaryo viloyati',
      title_cl: 'Сирдарё вилояти',
      title_en: 'Syrdarya region',
      admincenter_ru: 'г. Гулистан',
      admincenter_uz: 'Guliston sh.'
    },
    {
      ns10: 22,
      soato: "1722",
      title: 'Сурхандарьинская область',
      title_uz: 'Surxandaryo viloyati',
      title_cl: 'Сурхондарё вилояти',
      title_en: 'Surkhandarya region',
      admincenter_ru: 'г. Термез',
      admincenter_uz: 'Termiz sh.'
    },
    {
      ns10: 27,
      soato: "1727",
      title: 'Ташкентская область',
      title_uz: 'Toshkent viloyati',
      title_cl: 'Тошкент вилояти',
      title_en: 'Tashkent region',
      admincenter_ru: 'г. Нурафшон',
      admincenter_uz: 'Nurafshon sh.'
    },
    {
      ns10: 30,
      soato: "1730",
      title: 'Ферганская область',
      title_uz: 'Farg`ona viloyati',
      title_cl: 'Фарғона вилояти',
      title_en: 'Fergana region',
      admincenter_ru: 'г. Фергана',
      admincenter_uz: 'Farg`ona sh.'
    },
    {
      ns10: 33,
      soato: "1733",
      title: 'Хорезмская область',
      title_uz: 'Xorazm viloyati',
      title_cl: 'Хоразм вилояти',
      title_en: 'Khorezm region',
      admincenter_ru: 'г. Ургенч',
      admincenter_uz: 'Urganch sh.'
    },
    {
      ns10: 26,
      soato: "1726",
      title: 'Город Ташкент',
      title_uz: 'Toshkent shahri',
      title_cl: 'Тошкент шаҳри',
      title_en: 'Tashkent city',
      admincenter_ru: null,
      admincenter_uz: null
    }
  ]
}
