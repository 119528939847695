import {method} from "@/api/api";

export const actions = {
    getCategories({commit}, params) {
        return method("tickets.categories.list", params);
    },
    getTicketStatuses({commit}) {
        return method('help.ticket-statuses.list').then(res => {
            commit('SET_STATUSES', res['data'])
        })
    },
    getTickets({commit}, params) {
        return method('help.tickets.list', params).then(res => {
            commit('SET_LIST', res['data'])
        })
    },
    getTicket({commit}, params) {
        return method('help.tickets.show', params)
    },
    create({commit}, params) {
        return method('help.tickets.create', params)
    },
    update({commit}, params) {
        return method('help.tickets.update', params)
    },


    createNote({commit}, params) {
        return method('help.ticket-notes.create', params)
    },
    listNotes({commit}, params) {
        return method('help.ticket-notes.list', params)
    },


    createReply({commit}, params) {
        return method('help.ticket-replies.create', params)
    },
    listReply({commit}, params) {
        return method('help.ticket-replies.list', params)
    },

    
    histories({commit}, params) {
        return method('help.ticket-histories.list', params)
    }
}