const routes = [
  {
    path: '/kpi',
    name: 'KPI',
    meta: { middleware: 'Auth' },
    redirect: {name: 'KPIStructures'},
    component: () =>
    import("@/views/kpi/companies"),
  },{
    path: '/kpi/companies',
    name: 'KPICompanies',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/kpi/companies"),
  },{
    path: '/kpi/positions',
    name: 'KPIPositions',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/kpi/positions"),
  },{
    path: '/kpi/structures',
    name: 'KPIStructures',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/kpi/structures"),
  }
]

export default routes;
