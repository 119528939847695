export const state = {
  list: [],
  lead_list: [],
  crm_cards_count: {
    active: 0,
    expired: 0,
    archived: 0,
  },
  pagination: null,
  kpi: null,
  drag_step_index: null,
  company_list: [],
};
