import {method} from "@/api/api";

export const actions = {
    getList({commit}, params) {
        method('help.article-categories.list', params).then(res => {
            commit('SET_FAQ_CATEGORIES', res.data)
        })
    },
    getSingle({commit}, params) {
        return new Promise((resolve, reject) => {
            method(`help.articles.show`, params).then(res => {
                commit('SET_SINGLE_CATEGORY', res.data)
                resolve(res.data)
            }).catch(err => reject(err))
        })
    },
    create({commit}, params) {
        return method('help.article-categories.create', params)
    },
    update({commit}, params) {
        return method('help.article-categories.update', params)
    },
    destroy({commit}, id) {
        return method('help.article-categories.delete', id)
    }
}