const routes = [
  {
    path: '/hrm',
    name: 'HRM',
    meta: { middleware: 'Auth' },
    redirect: {name: 'HrmDepartments'},
    component: () =>
    import("@/views/hrm/departments/index"),
  },{
    path: '/hrm/filials',
    name: 'HrmFilials',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/hrm/filials/index"),
  },{
    path: '/hrm/departments',
    name: 'HrmDepartments',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/hrm/departments/index"),
  },{
    path: '/hrm/positions',
    name: 'HrmPositions',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/hrm/positions/index"),
  },{
    path: '/hrm/employees',
    name: 'HrmEmployees',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/hrm/employees/index"),
  },{
    path: '/hrm/employees/show/:id',
    name: 'HrmEmployeesShow',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/hrm/employees/show"),
  },{
    path: '/hrm/rights',
    name: 'HrmRights',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/hrm/rights/index"),
  }
]

export default routes;
