import { method } from '@/api/api';

export const actions = {
    getList({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("hrm.filials.list", params)
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_LIST', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getListArchive({ commit }, params) {
        return new Promise((resolve, reject) => {
            method("hrm.filials.list-archived", params)
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_ARCHIVED_LIST', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    create({ commit }, data) {
        return method("hrm.filials.create", data);
    },
    update({ commit }, data) {
        return method("hrm.filials.update", data);
    },
    show({ commit }, data) {
        return method("hrm.filials.show", data);
    },
    destroy({ commit }, data) {
        return method("hrm.filials.delete", data);
    },
    shortlist({ commit }, data) {
        return method("hrm.filials.shortlist", data);
    },
};