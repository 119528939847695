export const mutations = {
    SET_LIST: (state, data) => {
        if (data) {
            state.list = data['data'];
            state.pagination = {
                total: data['total'],
                from: data['from'],
                to: data['to'],
                last_page: data['last_page'],
                page: data['current_page'],
                limit: data['per_page'],
                startIndex: ((data['current_page'] - 1) * data['per_page']) + 1,
            }
        } else {
            state.list = [];
            state.pagination = null;
        }
    },
    SET_ARCHIVED_LIST: (state, data) => {
        if (data) {
            state.archivedList = data['data'];
            state.archivedPagination = {
                total: data['total'],
                from: data['from'],
                to: data['to'],
                last_page: data['last_page'],
                page: data['current_page'],
                limit: data['per_page'],
                startIndex: ((data['current_page'] - 1) * data['per_page']) + 1,
            }
        } else {
            state.archivedList = [];
            state.archivedPagination = null;
        }
    },
};