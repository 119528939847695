import { method } from "@/api/api";

export const actions = {
  getList({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.notifications.list")
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_LIST", res.data.notifications);
            commit("SET_COUNT", res.data.unread_notifications_count);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  readAll() {
    return method("crm.notifications.read-all");
  },
};
