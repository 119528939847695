import {method} from "@/api/api";

export const actions = {
    getList({commit}, params) {
        method('help.articles.list', params).then(res => {
            commit('SET_ARTICLES', res.data)
        })
    },
    create({commit}, params) {
        return method('help.articles.create', params)
    },
    update({commit}, params) {
        return method('help.articles.update', params)
    },
    destroy({commit}, id) {
        return method('help.articles.delete', id)
    }
}