import { method } from '@/api/api';

export const actions = {
  countries({ commit },params) {
    return new Promise((resolve, reject) => {
      method("crm.resource.countries", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_COUNTRIES', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formOptions({ commit },params) {
    return new Promise((resolve, reject) => {
      method("crm.resource.form-options", params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_FORM_OPTIONS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

   InnList({commit}, params) {
    return new Promise((resolve, reject) => {
        method("crm.residents.list", params)
            .then(res => {
                if (res['success'] && res['data']) {
                    commit('SET_INN_LIST', res['data']);
                }
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
   }
};
