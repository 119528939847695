import { method } from '@/api/api';

import { setToken, setTokenTime, clearForLogout } from '@/utils/auth';

export const actions = {
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("auth.login", data)
        .then(res => {
          if (res['success'] && res['data']) {
            setToken(res['data']['access_token']);
            setTokenTime(res['data']['expires_in']);
            commit('SET_IS_AUTH', true);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  foreignLogin({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("auth.foreign", data)
        .then(res => {
          if (res['success'] && res['data']) {
            setToken(res['data']['access_token']);
            setTokenTime(res['data']['expires_in']);
            commit('SET_IS_AUTH', true);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  currentUser({ commit }) {
    return new Promise((resolve, reject) => {
      method("auth.profile")
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_USER', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  setTokenAction({ commit }, params) {
    return new Promise((resolve, reject) => {
      setToken(params['token']);
      setTokenTime(params['expires_in']);
      commit('SET_IS_AUTH', true);
      resolve(true);
    });
  },
  logout({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("auth.logout")
        .then(res => {
          clearForLogout();
          commit('SET_IS_AUTH', false);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
