import { orderBy } from "@/utils/index";
export const mutations = {
  SET_LIST: (state, data) => {
    state.list = data;
  },
  SET_DRAG_STEP_INDEX: (state, index) => {
    state.drag_step_index = index;
  },
  SET_CARDS_COUNT: (state, data) => {
    if (data && data.length) {
      state.crm_cards_count = data[0];
    }
  },
  SET_LEAD_LIST: (state, list) => (state.lead_list = list),
  SET_COMPANY_LIST: (state, list) => (state.company_list = list),
  SET_KPI: (state, data) => {
    state.kpi = data.map((item) => {
      item["workers"] = orderBy(item["workers"], "position_order");
      return item;
    });

    // if (state.kpi.workers && state.kpi.workers.length) {
    //   state.kpi.workers = state.kpi.workers.map((item) => {
    //     item["all_count"] = 0;
    //     if (item["steps"]) {
    //       item["all_count"] = sumValues(item["steps"]);
    //     }
    //     return item;
    //   });
    // }
  },
};

function sumValues(obj) {
  // var sum = 0;
  // console.log(obj);
  // Object.values(obj).forEach(val => {
  //   console.log(val);
  //   sum += val;
  // })
  return Object.keys(obj).reduce(
    (sum, key) => sum + parseFloat(obj[key] || 0),
    0
  );
}
