import { method } from "@/api/api";

export const actions = {
  getList({ commit }, params) {
    return method("crm.tasks.list", params);
  },
  create({ commit }, data) {
    return method("crm.tasks.create", data);
  },
  update({ commit }, data) {
    return method("crm.tasks.update", data);
  },
  show({ commit }, data) {
    return method("crm.tasks.show", data);
  },
  destroy({ commit }, data) {
    return method("crm.tasks.delete", data);
  },
  shortlist({ commit }, data) {
    return method("crm.tasks.shortlist", data);
  },
  histories({ commit }, data) {
    return method("crm.tasks.histories", data);
  },
  storeComments({ commit }, data) {
    return method("crm.tasks.create-comments", data);
  },
  getComments({ commit }, data) {
    return method("crm.tasks.get-comments", data);
  },
  archived({ commit }, data) {
    return method("crm.tasks.archived", data);
  },
  fileDelete({ commit }, data) {
    return method("crm.tasks.filedelete", data);
  },
};
