import { method } from "@/api/api";

export const actions = {
  getList({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.infrastructures.list", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_LIST", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getRegionData({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.infrastructures.get-by-region", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_REGION_DATA", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  create({ commit }, data) {
    return method("crm.infrastructures.create", data);
  },
  edit({ commit }, data) {
    return method("crm.infrastructures.edit", data);
  },
  delete({ commit }, data) {
    return method("crm.infrastructures.delete", data);
  },
};
